<template>
	<div class='Wrapper h100 displayFlex'>
		<!-- 左侧图片 -->
		<div class="displayFlex-1">
			<img src="../../assets/img/login.bg.png" alt="" class="h100 w100">
			<span class="versionSet">Version {{version}}</span>
		</div>
		<!-- 右侧表单 -->
		<div class="form-box pr80px">
			<!-- 文字 inFP -->
			<span class="fontSet" style="color: #F19E54;">in</span>
			<span class="fontSet">FP</span>
			<!-- 文字 for Adviser -->
			<p class="font30" style="color: #8B8B8B;">for Adviser</p>
			<div class="middle-box">
				<!-- 用户名密码框\登录按钮 -->
			<el-form :model="userForm" ref="userForm" v-if="$store.state.User.Step === '1'">
				<el-form-item prop="username" :rules="[{ required: true, message: 'Please enter Adviser reference ID', trigger: 'blur' }]">
					<el-input v-model="userForm.username" style="margin-top:8vh;" placeholder="Adviser reference ID"></el-input>
				</el-form-item>
				<el-form-item prop="password" :rules="[{ required: true, message: 'Please enter Password', trigger: 'blur' }]">
					<el-input v-model="userForm.password" placeholder="Password" show-password @keyup.enter.native="$store.state.User.Step === '1' ? login() : codeLogin()"></el-input>
				</el-form-item>
			</el-form>
			<!-- 发送验证码 -->
			<el-form :model="codeForm" ref="codeForm" v-if="$store.state.User.Step === '2'">
				<el-form-item>
					<div class="Send_Code">
						<p style="color: #707070;">We have sent a verification code to your registered mobile.</p>
						<p @click="$store.commit('SET_STEP', '1')" style="color: #FF9941;text-align: right;cursor: pointer;" class="mr20px">Cancel</p>
					</div>
				</el-form-item>
				<el-form-item class="Send_Code_flex" prop="Code" :rules="[{ required: true, message: 'Please enter Code', trigger: 'blur' }]" >
					<el-input v-model="codeForm.Code" placeholder="Code" style="width: 85%"></el-input>
					<el-button @click="getCount()" type="info" size="medium" style="padding: 18px 20px;font-size: 20px;width: 145px;">{{buttonText}}</el-button>
				</el-form-item>
			</el-form>
			<el-button @click="$store.state.User.Step === '1' ? login() : codeLogin()" type="warning" size="medium" style="width: 100%;font-weight: bold;padding: 3vh;font-size: 24px;margin-top:4vh;">Sign in</el-button>
			<!-- 忘记密码 -->
			<p class="forgotPStyle" @click="forgotPwd">Unlock Account / Reset Password</p>
			</div>
		</div>
	</div>
</template>

<script>
import { getVideoupload } from '@/api/Home/index.js'
export default {
	components: {},
	data() {
		return {
			userForm: {
				username: '',
				password: '',
			},
			codeForm: {
				Code: ''
			},
			buttonText: 'Send Code',
			timer: null,
			show: true,
			version: window.version
		};
	},
	computed: {
		step() {
			return this.$store.state.User.Step
		}
	},
	watch: {
		step(newVal, oldVal) {
			if (newVal !== oldVal && newVal === '2') {
				this.getCount()
			}
		}
	},
	methods: {
		// 账号密码登录
		login() {
			this.$refs.userForm.validate((valid) => {
				if (!valid) return false
				// 加密密码
				const jse = new this.$jsEncrypt()
				jse.setPublicKey('MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJA/5yMlBmrqR7fM3zVV81QrgbOx6Fm59LPEhZKKAtGm2zxF7Q+tj4odC4zsYmtNlDq2JablbLZl/FFNWoHJu30CAwEAAQ==')
				let param = {
					browser: 'web',
					infinitum_rep_code: this.userForm.username,
					password: jse.encrypt(this.userForm.password)
				}
				this.$store.dispatch('LoginByUserName', param).then(() => this.getData())
			})
		},
		// 验证码登录
		codeLogin() {
			this.$refs.codeForm.validate((valid) => {
				if (!valid) return false
				let param = {
					browser: 'web',
					infinitum_rep_code: this.userForm.username,
					// 登录1 忘记密码2
					verificationCodeType: 1,
					verification_code: this.codeForm.Code,
				}
				this.$store.dispatch('LoginByVerificationCode', param).then(() => this.getData())
			})
		},
		// 页面数据接口获取
		getData() {
			// 获取首页视频
			getVideoupload({}).then(res => {
				localStorage.setItem('picture_attachment', res.content.picture_attachment)
				localStorage.setItem('video_address', res.content.video_address)

			})
			this.$router.push('/')
			// 登录页进入，选中Home
			this.$store.commit('SET_PAGEITEM', 0)
		},
		// 忘记密码
		forgotPwd() {
			if (this.userForm.username) {
				this.$router.push('/ResetPassword?name=' + this.userForm.username)
			} else {
				this.$router.push('/ResetPassword')
			}
		},
		getCount() {
			const TIME_COUNT = 60
			if (!this.timer) {
				this.buttonText = TIME_COUNT
				this.timer = setInterval(() => {
					if (this.buttonText > 0 && this.buttonText <= TIME_COUNT) {
						this.buttonText--
					} else {
						clearInterval(this.timer)
						this.timer = null
						this.buttonText = 'Send Code'
					}
				}, 1000);
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.$store.commit('SET_STEP', '1')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
}
</script>
<style lang='css' scoped>
 @media (max-width:376px){
.forgotPStyle{
	margin-top: 10px !important;
	float: right;
	text-align: right;
}
}

@media (max-height:430px){
.fontSet{
	font-size: 50px !important;
}
.forgotPStyle{
	margin-top: 10px !important;
}
.mt70px,.mt100px{
	margin-top: 10px !important;
}
.mb70px{
	margin-bottom:0px !important ;
}
.el-button{
	margin-top: 0px !important;
}
/* .form-box{
	margin-top: 1vh !important;
} */
.el-input >>> .el-input__inner{
	height: 12vh !important;
}
}
.form-box{
	width: 45%;
	/* overflow-y: scroll; */
	height:100vh;
	margin-top: 10vh;
}
.middle-box{
	height: 40vh;
}
.versionSet {
	position: relative;
	bottom: 50px;
	left: 20px;
	color: #6c6c6c;
}
.fontSet {
	font-weight: 600;
	font-size: 100px;
}
.el-input >>> .el-input__inner {
	font-size: 20px;
	height: 9vh;
	padding: 2vh 1vh;
	/* 只显示下边框 */
	border-top-width: 0px;
	border-left-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 1px;
}
.forgotPStyle {
	font-size: 24px;
    color: #6c6c6c;
    margin-top: 20px;
    float: right;
    cursor: pointer;
}
.el-form >>> .el-form-item__error {
	font-size: 16px;
}
.Send_Code {
	border: 1px solid #ff9941;
	border-radius: 5px;
	padding: 20px;
	font-size: 20px;
	margin-top: 58px;
}
.Send_Code_flex >>> .el-form-item__content {
	display: flex;
	justify-content: space-around;
}
</style>